import React from "react";
import NextApp, { Container } from "next/app";
import { AppProvider as PolarisAppProvider } from "@shopify/polaris";
import { ShopifyBridgeProvider } from "@components";
import { Bugsnag } from "@utils";
import "@shopify/polaris/styles.css";
import "@styles/index.css";

// == Types ================================================================

// == Constants ============================================================

const BugsnagErrorBoundary = Bugsnag.getPlugin("react");

// == Functions ============================================================

// == Component ============================================================

export default class App extends NextApp {
  render() {
    const { Component, pageProps } = this.props;
    return (
      <BugsnagErrorBoundary>
        <Container>
          <PolarisAppProvider>
            <ShopifyBridgeProvider>
              {(shopOrigin) => <Component {...pageProps} shopOrigin={shopOrigin} />}
            </ShopifyBridgeProvider>
          </PolarisAppProvider>
        </Container>
      </BugsnagErrorBoundary>
    );
  }
}

// == Styles ===============================================================
